body{
  font-family: Arial Rounded MT Bold, sans-serif;
  font-size: 18px;
}
.container {
  display: flex;
  align-items: center;
}
.header-name{
  font-size: 25px;
  display: flex;
  align-items: center;
}
.sub-container{
  padding: 50px 30px 30px 30px;
}
.flex-column{
  display:flex;
  flex-direction: column;
}
@media only screen and (max-device-width: 480px) {
  body {
      font-size: 20px;
  }
  .set-list-paper {
    margin-top: 40px !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
a {
  color: black;
}

.piano {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  width: 100px;
  background-color: black;
}
.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image{
  margin-bottom:20px;
  border-radius: 10px;
}
.keyboard .white-key {
  height: 25px;
  width: 100px;
  background-color: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.535);
  transition: 0.1s ease-in-out;
}
.keyboard .white-key.active {
  transition: 0.1s ease-in-out;
  background-color: #f0f0f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.53);
  border-bottom: 2px solid #e1e1e1;
}
.keyboard .black-key {
  height: 10px;
  width: 50px;
  background-color: #555;
  margin-top: -10px;
  margin-bottom: -10px;
  position: relative;
  z-index: 999;
  align-self: flex-start;
  margin-top: 0px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  transition: 0.1s ease-in-out;
}
.keyboard .black-key.active {
  transition: 0.1s ease-in-out;
  border-left: 2px solid #333;
  background-color: #444;
}
.piano-top{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.set-list{
  width: 50px;
  height: 70px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  position: absolute;
  margin-left: 25px;
}
.about-me{
  width: 60px;
  height: 30px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  position: absolute;
  margin-left: 100px;
}

.steinway{
  color: gold;
  margin-right: auto;
  margin-left: auto;
  font-size: 12px;
  rotate: 270deg;
  width: 300px;
}
.set-list-title{
  font-size: 10px;
}
li{
  margin-bottom: .75em;
}
.set-list-paper{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-radius: 5px;
  max-width: 500px;
  padding-bottom: 20px;
  margin-top: 10px;
}

.set-list-item{
  font-size: 13px;
  cursor: pointer;
}

.coffee {
  position: absolute;
  cursor: pointer;
  margin-left: 200px;
  width:15px;
  height:15px;
  border-radius:50%;
  background-color: #c47645;
  background-clip:content-box;
  padding:0px;
  border:3px solid #fffdf5;
}

.record-player {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.record {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: black;
  border-radius: 50%;
  overflow: hidden;
}

.record-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background-color: #ecf0f1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid gold;
}

.record-sheen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1) 2px,
      rgba(255, 255, 255, 0.3) 2px,
      rgba(255, 255, 255, 0.3) 4px
  );

  animation: spin 2s linear infinite;
  animation-play-state: paused;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinning .record-sheen {
  animation-play-state: running;
}